import React from 'react'

export default (
  <svg viewBox="0 0 72 72">
    <g fill="none" fillRule="evenodd">
      <circle cx="36" cy="36" r="36" fill="#FFF" fillOpacity=".95" />
      <path
        fill="#444"
        fillRule="nonzero"
        d="M50.8350169,37.0602664 L29.4767217,49.9693832 C28.900608,50.3175908 28.1558807,50.1251285 27.8133266,49.5395068 C27.701749,49.3487566 27.6428571,49.1309436 27.6428571,48.9090213 L27.6428571,23.0907876 C27.6428571,22.4094644 28.1862113,21.8571429 28.8564727,21.8571429 C29.0747919,21.8571429 29.2890685,21.9170066 29.4767217,22.0304257 L50.8350169,34.9395425 C51.4111306,35.28775 51.6004681,36.0447682 51.257914,36.6303899 C51.154433,36.8072984 51.0090531,36.9550776 50.8350169,37.0602664 Z"
      />
    </g>
  </svg>
)
