import React from 'react'

export default (
  <svg viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width="24" height="24" />
      <path
        fill="#FFF"
        d="M12.4049973,3.62840003 C17.1876069,3.84468032 21,7.87252205 21,12.8096305 C21,17.8853314 16.9705627,22 12,22 C7.02943725,22 3,17.8853314 3,12.8096305 C3,12.0229797 3.624497,11.3852732 4.39485308,11.3852732 C5.16520917,11.3852732 5.78970616,12.0229797 5.78970616,12.8096305 C5.78970616,16.3120298 8.57014942,19.1512855 12,19.1512855 C15.4298506,19.1512855 18.2102938,16.3120298 18.2102938,12.8096305 C18.2102938,9.44617464 15.6460805,6.69429107 12.4049973,6.4812447 L12.4049973,8.30324795 C12.4049973,8.71101381 12.0954942,9.04157334 11.7137028,9.04157334 C11.5841406,9.04157334 11.457186,9.00268616 11.3473174,8.92934663 L6.42999892,5.64693477 C6.10624047,5.4308193 6.00781868,4.97530916 6.21016772,4.62952441 C6.26580359,4.53445089 6.34098153,4.45415835 6.42999892,4.3947374 L11.3473174,1.11232554 C11.6710759,0.896210069 12.0975703,1.00132779 12.2999193,1.34711255 C12.3685872,1.46445578 12.4049973,1.60004749 12.4049973,1.73842422 L12.4049973,3.62840003 Z"
      />
    </g>
  </svg>
)
